<template>
	<a-modal
	      title="Thông tin chuyển khoản ngân hàng"
	      :visible="visible"
	      :closable="false"
	      cancelText="Hủy"
	      okText="Xác nhận"
	      @ok="handleOk"
	      @cancel="handleCancel"
	    >
		<a-spin :spinning="loading">
	    	<a-input-search prefix="$" :value="value"
	    		class="mb-24"
	    		placeholder="Nhập số tiền cần nạp.." 
		    	size="large"
		    	@change="changeFormatMoney"
		    	@search="getQR">
		      <a-button slot="enterButton">Nhận QR</a-button>
		    </a-input-search>
		    <a-row v-if="data.qr" type="flex">
		    	<a-col :span="24" :lg="12" class="text-center mb-24">
		    		<qrcode-vue :value="data.qr" size="300" level="H" />
		    	</a-col>
		    	<a-col :span="24" :lg="12">
					<p>Ngân hàng : <b>{{data.bank}}</b></p>
					<p>Chủ tài khoản : <b>{{data.account_name}}</b></p>
					<p>Số tài khoản : <b>{{data.account}}</b></p>
					<p>Nội dung : <b>{{data.content}}</b></p>
		    		<p>Số tiền : <b>{{data.money}}</b></p>
		    	</a-col>
		    	<a-col :span="24">
		    		<h6>Chú ý</h6>
					<p>1. Để số dư được cập nhật nhanh chóng, quý khách vui lòng kiểm tra thông tin chuyển khoản đúng Ngân hàng, Số tiền, Số tài khoản, Chủ tài khoản, Nội dung</p>
					<p>2. Không tự ý thay đổi nội dung chuyển khoản</p>
					<p>3. Nếu có thắc mắc về vấn đề chuyển khoản, vui lòng liên hệ với chúng tôi</p>
		    	</a-col>
		    </a-row>
		</a-spin>
	</a-modal>
</template>

<script>
	import QrcodeVue from 'qrcode.vue'
	import getQrcode from "@/services/bill/getQrcode"

	export default ({
		components: {
			QrcodeVue,
		},
		data() {
			return {
				value : null,
				data : {},
				loading : false
			}
		},
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
		},
		methods: {
			async getQR(){
				this.loading = true;
				let result = await getQrcode(this.value.replace(/\D/g,''));
				if(result.status == 1){
					this.data = result.data
				}
				this.loading = false;
			},

			handleOk(){
				this.$emit('close')
				this.data = {}
				this.value = null
			},
			
			handleCancel(){
				this.$emit('close')
			},

			changeFormatMoney(e){
				this.value = e.target.value.replace(/\D/g,'').replace(/\B(?=(\d{3})+(?!\d))/g,',')
			}
		}
	})
</script>

<style lang="scss">
	.ant-modal{
		width: 50% !important;
	}
	@media only screen and (max-width: 1360px) {
		.ant-modal{
			width: 70% !important;
		}
	}
	@media only screen and (max-width: 991px) {
		.ant-modal{
			width: 75% !important;
		}
	}

</style>